 <template>
    <div>
        <b-card-code title="Listing details"><span v-if="isListingDetailsValid" class="text-danger">Please fill the empty fields.</span>
            <b-form @submit.prevent>
                <b-row>
                    <b-col cols="8">
                        <b-row class="match-height">
                            <b-col cols="12">
                                <b-form-group label-for="v-title">
                                    <span class="label-text">Title<span class="mandatory-field"
                                            v-if="form.title === ''">*</span></span>
                                    <b-form-input v-model="form.title" id="v-title" placeholder="Title"
                                        aria-required="please add this asfsfdfasfdasdf" />
                                    <span class="mandatory-field" v-if="form.title === ''">This field is required</span>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-form-group label-for="v-sku">
                                    <span class="label-text">Sku<span class="mandatory-field"
                                            v-if="form.sku === ''">*</span></span>
                                    <b-form-input v-model="form.sku" id="v-sku" placeholder="SKU" />
                                    <span class="mandatory-field" v-if="form.sku === ''">This Field is required</span>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-form-group label-for="v-sku">
                                    <span class="label-text">Price<span class="mandatory-field"
                                            v-if="form.price === ''">*</span></span>
                                    <b-form-input v-model="form.price" id="v-price" placeholder="Price" />
                                    <span class="mandatory-field" v-if="form.price === ''">This Field is required</span>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-form-group label="Supplier:" label-for="input-supplier">
                                    <b-form-select v-model="selectedSupplier" :options="supplier_options"></b-form-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-form-group label-for="v-category">
                                    <b-dropdown class="custom-dropdown" v-model="form.category" id="dropdown-grouped-amount"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                        :text="categoryDropDownText" dropright>
                                        <b-dropdown-item v-for="(category, index) in ebay_cat.vehicle_cat"
                                            :key="category[index]"
                                            @click="selectedCategory(category['Category Path'].replace('Vehicle Parts & Accessories > Car & Truck Parts > ', ''), category['CategoryID'])">
                                            {{ category['Category Path'].replace('Vehicle Parts & Accessories > Car & TruckParts > ','')}}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="4" style="position: absolute;left: 76%;">
                        <div id="my-strictly-unique-vue-upload-multiple-image"
                            style="display: flex; justify-content: center; width: 200px; height: 200px;">
                            <vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove"
                                @edit-image="editImage" @limit-exceeded="limitExceeded" :data-images="images"
                                idUpload="myIdUpload" editUpload="myIdEdit" dragText="Upload Photos" browseText=""
                                primaryText="Main photo" markIsPrimaryText="Make main photo" dropText="Drag and drop"
                                popupText="Cover photo" :maxImage="maxImages" multiple>
                            </vue-upload-multiple-image>
                        </div>
                    </b-col>
                </b-row>


            </b-form>

        </b-card-code>
        <b-card-code title="Description*">
            <b-row>
                <b-col cols="12">

                    <quill-editor v-model="content" :options="editorOption" placeholder="Auto Square">
                        <div id="toolbar" slot="toolbar">
                            <button class="ql-bold">
                                Bold
                            </button>
                            <button class="ql-italic">
                                Italic
                            </button>
                            <select class="ql-size">
                                <option value="small" />
                                <option selected />
                                <option value="large" />
                                <option value="huge" />
                            </select>

                            <select class="ql-font">
                                <option selected="selected" />
                                <option value="serif" />
                                <option value="monospace" />
                            </select>

                            <!-- Add subscript and superscript buttons -->
                            <button class="ql-script" value="sub" />
                            <button class="ql-script" value="super" />


                        </div>
                    </quill-editor>
                </b-col>
            </b-row>
        </b-card-code>
        <div class="flex">
            <b-card-code class="resize" title="Item Specifics">
                <b-form @submit.prevent>
                    <b-row>

                        <b-col cols="12">
                            <div>
                                <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="x">

                                    <!-- Row Loop -->
                                    <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">

                                        <!-- Item Name -->
                                        <b-col md="4">
                                            <b-form-group label="Name" label-for="name">
                                                <b-form-input id="name" v-model="item.name" type="text"
                                                    placeholder="i.e Brand" />
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="4">
                                            <b-form-group label="Value" label-for="value">
                                                <b-form-input id="value" v-model="item.value" type="text"
                                                    placeholder="i.e Toyota" />
                                            </b-form-group>
                                        </b-col>

                                        <!-- Remove Button -->
                                        <b-col>
                                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                                                class="mt-0 mt-md-2" @click="removeItem(index)">
                                                <feather-icon icon="XIcon" class="mr-25" />
                                                <span>Delete</span>
                                            </b-button>
                                        </b-col>
                                        <b-col cols="12">
                                            <hr>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </div>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Add New</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card-code>
            <b-card-code class="resize" title="Package Weight And Dimensions">
                <b-form @submit.prevent>
                    <b-row>
                        <label style="display:inline; font-size:15px;">Dimensions</label>
                        <b-col cols="3">
                            <b-form-group label="" label-for="v-lenght">
                                <b-input-group append="cm" class="input-group-merge">
                                    <b-form-input id="v-lenght" v-model="form.lenght" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="" label-for="v-width">
                                <b-input-group append="cm" class="input-group-merge">
                                    <b-form-input id="v-width" v-model="form.width" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="" label-for="v-height">
                                <b-input-group append="cm" class="input-group-merge">
                                    <b-form-input id="v-height" v-model="form.height" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <label style="display:inline; font-size:15px;">Weight</label>

                        <b-col cols="3">
                            <b-form-group label="" label-for="v-kg">
                                <b-input-group append="kg" class="input-group-merge">
                                    <b-form-input id="v-kg" v-model="form.kg" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="" label-for="v-grams">
                                <b-input-group append="g" class="input-group-merge">
                                    <b-form-input id="v-grams" v-model="form.grams" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card-code>
        </div>
        <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" @click.prevent="onSubmit"
                class="mr-1">
                Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                Reset
            </b-button>
        </b-col>
    </div>
</template>

<script>

import {
    BRow, BCol, BFormGroup, BFormSelect, BFormInput, BForm, BButton, BDropdownGroup,
    BDropdownItem, BDropdown, BInputGroup
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import ebay_cat from './ebay_cat'
import axios from 'axios'
import DOMPurify from 'dompurify';
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import router from '@/router';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    name: 'app',
    components: {
        quillEditor,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BFormSelect,
        BButton,
        BCardCode,
        BDropdownGroup,
        BDropdownItem,
        ToastificationContent,
        VueUploadMultipleImage,
        BDropdown,
        BInputGroup,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {

        return {
            items: [{
                id: 1,
                name: '',
                value: '',
                prevHeight: 0,
            }],
            nextTodoId: 2,
            editorOption: {
                modules: {
                    toolbar: '#toolbar',
                },
            },
            content: `<h2><span class="ql-font-serif" style="text-align: center;"></span></h2><br />`,
            sanitizedContent: '',
            ebay_cat,
            loader: false,
            maxImages: 12,
            images: [],
            super_supp_id: [],
            formPhoto: [],
            errorMessage: null,
            categoryDropDownText: 'Select Category',
            form: {
                title: '',
                price: '',
                sku: '',
                quantity: '',
                name: '',
                value: '',
                lenght: '',
                width: '',
                height: '',
                kg: '',
                grams: '',
            },
            supplier_options: [],
        }
    },

    mounted() {
        this.initTrHeight();
        this.sanitizeContent();
    },

    created() {
        this.getAllsuppliers();
        // window.addEventListener('resize', this.initTrHeight)
    },

    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },

    watch: {
        super_supp_id: function (newVal) {
            // Manually trigger a reactivity update when super_supp_id changes.
            this.$nextTick(() => {
                this.super_supp_id = newVal;
            });
        }
    },

    computed: {
        isListingDetailsValid() {
            return !(
                this.form.title &&
                this.form.sku &&
                this.form.price &&
                this.selectedSupplier
            );
        },
        selectedSupplier: {
            get() {
                return this.super_supp_id;
            },
            set(newValue) {
                this.super_supp_id = newValue;
                this.form.supplier = newValue; // Update form.supplier when the value changes
            }
        }
    },

    methods: {


        handleSupplierChange() {
            // Update the form.supplier property with the selected value.
            this.form.supplier = this.super_supp_id;
        },
        sanitizeContent() {
            // Use DOMPurify to sanitize the HTML and remove any unsafe elements
            this.sanitizedContent = DOMPurify.sanitize(this.content, { USE_PROFILES: { html: true } });
        },
        getAllsuppliers() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/super-supplier")
                .then((res) => {
                    this.loader = false;
                    this.supplier_options = res["data"].map((option) => ({
                        text: option.name, // Display name for the radio button
                        value: option.id, // Value for the radio button
                    }));
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.nextTodoId,
                name: this.name,
                value: this.value,
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
        onSubmit(event) {
            
            console.log('Form Data:', this.form);

            try {
                let data = {
                    'title': this.form.title,
                    'price': this.form.price,
                    'sku': this.form.sku,
                    'quantity': this.form.quantity,
                    'name': this.form.name,
                    'value': this.form.value,
                    'photo': this.formPhoto.join(", "),
                    'description': this.content,
                    'category': this.categoryDropDownText,
                    'lenght': this.form.lenght,
                    'width': this.form.width,
                    'height': this.form.height,
                    'kg': this.form.kg,
                    'grams': this.form.grams,
                    'supplier': this.super_supp_id,
                    'items': this.items,
                }

                axios({
                    method: 'post',
                    url: process.env.VUE_APP_API + '/prdct',
                    data
                })
                    .then(res => {
                        // alert()
                        if (res.data.success) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: res.data.message,
                                    icon: 'AlertOctagonIcon',
                                    text: 'Product is ready',
                                    variant: 'success',
                                },
                            }, {
                                position: 'top-right'
                            });
                            this.$emit('refresh');
                            router.push('/products');
                        } else {
                            // Error case - show an error notification

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Validation Error',
                                    icon: 'AlertOctagonIcon',
                                    text: this.errorMessage,
                                    variant: 'danger',
                                },
                            }, {
                                position: 'top-left',
                            });
                        }
                    })
            } catch (error) {
                console.error(error);
            }
        },
        selectedCategory(category, category_id) {
            this.categoryDropDownText = category
        },
        uploadImageSuccess(formData, index, fileList) {

            this.formPhoto = [];
            for (let i = 0; i < fileList.length; i++) {
                this.formPhoto.push(fileList[i].path);
            }
            // console.log(fileList[0].path)
            // this.formPhoto.push(fileList[0].path)
        },
        beforeRemove(index, done, fileList) {
            console.log('index', index, fileList)
            done()
        },
        editImage(formData, index, fileList) {
            console.log('edit data', formData, index, fileList)
        },
        limitExceeded(amount) {
            if (amount > this.maxImages) {
                alert(1)
            }
        }
    }
}
</script>

<style>
.btnupload {
    position: absolute;
    left: 130%;
}

#my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}

.resize {
    width: 50% !important;
}

h1,
h2 {
    font-weight: normal;
}

.flex {
    display: flex;
    gap: 1%;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

.custom-dropdown .dropdown-menu {
    width: 800px;
    max-height: 300px;
    overflow-y: auto;
}

.image-container[data-v-6ff5a0de] {
    width: 304px;
    /* margin-left: 20%; */
    height: 269px;
}

.show-img[data-v-6ff5a0de] {
    max-height: 170px;
    max-width: 170px;
    display: block;
    vertical-align: middle;
}

.image-list-container[data-v-6ff5a0de] {
    margin-top: 10px;
    margin-left: 8%;
    max-width: 730px;
    min-height: 50px;
}

.preview-image[data-v-6ff5a0de] {
    height: 205px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: #42b983;
}

.repeater-form {
    overflow: hidden;
    transition: .35s height;
}</style>